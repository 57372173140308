html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #111;
}

body {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1;
  overflow-y: auto;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.filter .chakra-checkbox__control {
  margin-top: 5px;
}

@media screen and (max-width: 650px) {
  .pac-container {
    z-index: 2000;
    pointer-events: none;
  }

  .pac-item {
    pointer-events: all;
    position: relative;
  }
}